import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";
import DefaultNavbar from "hanyeleo/Navbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import EventCard from "pages/Home/sections/EventCard";
import Testimonials from "pages/Home/sections/Testimonials";
import EventList from "pages/Home/sections/EventList";
// import routes from "routes";
import navRoutes from "navroutes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";
import product5 from "assets/images/HanyeIcon.png";

import { useNavigate } from "react-router-dom";

const itemsPerPage = 10;
const todayEvents = [
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
  {
    id: 71,
    time: "Sunday Sept 8th, 4pm - Until Late",
    title: "R&R Sundays",
    location: "Black Samurai - Nairobi",
    backgroundImage: "https://uploads.kazana.dev/event_cover_66db182cae255_IMG-20240906-WA0018.jpg",
  },
];


function Home() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const fetchEvents = async (page = 1) => {
    Axios.get(`?type=event&event_time=future`)
      .then((response) => {
        const allData = response.data;
        setData(allData);
        setTotalPages(Math.ceil(allData.length / itemsPerPage));
      })
      .catch((error) => {
        console.error("Failed to fetch events data", error);
      });
  };

  useEffect(() => {
    fetchEvents(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const SignIn = () => {
    navigate("/sign-in/"); // Redirect to login page
  };

  const EventGalleries = () => {
    navigate("/pages/histories"); // Redirect to login page
  };
  

  // Slice the data for the current page
  const paginatedData = data.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <>
      <DefaultNavbar routes={navRoutes} relative center />

      <section className="bg-gray-100">
        <Container>
          <div className="flex flex-col-reverse md:flex-row items-center justify-between py-12 md:space-x-16 px-6">
            <div className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
              <h1 className="text-3xl md:text-5xl font-bold text-black mb-4">
                Great Events Start at <span className="text-purple-800">HanyeLeo</span>
              </h1>
              <p className="text-gray-700 mb-6">
                Welcome to HanyeLeo, bringing you the hottest events and parties in Nairobi!
              </p>
              <p>
                Browse our event pages to see what's going on, and come back to view our image galleries of previous events.
              </p>
              <br></br>
              <center>
                <button 
                  type="button" 
                  onClick={SignIn}
                  className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm px-16 py-3 me-2 mb-2">
                  Get Started
                </button>
                <button 
                  type="button" 
                  onClick={EventGalleries}
                  className="text-white bg-purple-800 hover:bg-purple-900 focus:ring-4 focus:ring-purple-400 font-medium rounded-lg text-sm px-16 py-3 me-2 mb-2">
                  Event Galleries
                </button>
                </center>
            </div>
            <div className="w-full md:w-1/2 flex items-center md:justify-end">
              <img src={product5} alt="Landing Image" />
            </div>
          </div>
        </Container>
      </section>

      <Container sx={{ mt: 5 }} className="pb-2">
        <div className="text-center mb-16">
          <p className="text-3xl md:text-5xl font-bold text-gray-700 mb-4">Top Events</p>
        </div>
        <Grid container spacing={3}>
          {todayEvents?.map((event) => (
            <EventCard key={event.title} {...event} />
          ))}
        </Grid>
      </Container>

      <EventList events={paginatedData} />

      <Container sx={{ mt: 5 }}>
        <MKPagination count={totalPages} page={page} onChange={handlePageChange}>
          <MKPagination item onClick={() => handlePageChange(null, Math.max(1, page - 1))}>
            <Icon>keyboard_arrow_left</Icon>
          </MKPagination>
          {[...Array(totalPages).keys()].map((pageNumber) => (
            <MKPagination
              item
              key={pageNumber + 1}
              onClick={() => handlePageChange(null, pageNumber + 1)}
              active={page === pageNumber + 1}
            >
              {pageNumber + 1}
            </MKPagination>
          ))}
          <MKPagination item onClick={() => handlePageChange(null, Math.min(totalPages, page + 1))}>
            <Icon>keyboard_arrow_right</Icon>
          </MKPagination>
        </MKPagination>
      </Container>

      <Testimonials />

        <DefaultFooter content={footerRoutes} />

    </>
  );
}

export default Home;
