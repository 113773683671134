import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import DefaultNavbar from "hanyeleo/Navbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import routes from "navroutes";
import footerRoutes from "footer.routes";
import Axios from "utils/Axios";

const imageUrl = (url) => {
  return process.env.REACT_APP_BASE_IMAGE_URL + url;
};
const galleryImageUrl = (image) => {
  return `${process.env.REACT_APP_BASE_IMAGE_URL}gallery/img_800_${image}`;
};
const EventImage = () => {
  // get user info from localstorage;
  const { imgeRef, eventId, imageId } = useParams();
  const [singleImage, setSingleImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // get that singleImage
  const getSingleImage = () => {
    Axios.get(`?type=eventSingleMedia&event_id=${eventId}&image_id=${imageId}`)
      .then((response) => {
        const image = response.data; 
        setSingleImage(image);
        setLoading(false);
        console.log("Fetched Single Image:", image);
        // Do something with the image
      })
      .catch((error) => {
        console.error("Error fetching single image:", error);
        setError("Error fetching image");
        setLoading(false);
      });
  };

  useEffect(() => {
    getSingleImage();
  }, [eventId]);


  return (
    <>
      <DefaultNavbar
          routes={routes}
          center
          dark
        />
      <br></br>
      <div className="grid place-items-center h-screen">
      {/* Loading and error handling */}
      {loading && <p>Loading image...</p>}
      {error && <p>{error}</p>}

      {/* Centered image */}
      {!loading && singleImage && (
        <div className="w-full max-w-6xl mx-auto px-4 py-2">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-12 flex justify-center items-center">
              <img
                className="h-auto max-w-full rounded-lg"
                src={galleryImageUrl(singleImage[0].title, singleImage[0].id)}
                alt={singleImage[0].title}
              />
            </div>
          </div>
        </div>
      )}
    </div>
    <DefaultFooter content={footerRoutes} />
    </>
  );
};

export default EventImage;
