// @mui material components
import Icon from "@mui/material/Icon";

// react-router components
import { Link } from "react-router-dom";

//  HanyeLeo Pages
import History from "hanyeleo/events/history";

const navroutes = [
  {
    icon: <Icon>dashboard</Icon>,
    name: "Current Events",
    route: "/",
    component: <Link />
  },
  {
    icon: <Icon>dashboard</Icon>,
    name: "Past Event Galleries",
    route: "/pages/histories",
    component: <History />,
  }
];

export default navroutes;
